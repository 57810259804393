/* Home.css */
body {
  font-family: Axiforma;
}

.app-container {
  background: no-repeat center;
  height: 1027px;
  padding: 50px;
}

.app-heading {
  text-align: center;
  color: #fff;
  font-size: 70px;
  font-weight: 700;
  line-height: 82px;
  margin-top: 35px;
  font-family: Axiforma;
}

.logo {
  width: 290px;
  height: 70px;
}

.app-sideheading {
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 25px;
  line-height: 40px;
  margin-top: 50px;
}
.container-image {
  width: 100%;
}
