body {
  font-family: Axiforma;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  color: #333;
}
.container {
  max-width: 1000px;
  margin: 1rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 0 !important;
}
.header {
  text-align: center;
  padding-bottom: 20px;
  background-image: url('https://lifewink-common.s3.us-east-2.amazonaws.com/emailAssets/background.png');
  background-size: cover;
  background-position: center;
  height: 130px;
}
.lifewink_logo {
  margin-top: 40px;
}
.content {
  text-align: left;
  padding: 1rem;
}
.second_content {
  text-align: center;
}
.content h1 {
  font-size: 30px;
  font-weight: 700;
  line-height: 36.31px;
}
.name--primary {
  color: #0169eb;
}
.content p {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 5px 0;
}
.paragraph_two,
.paragraph_three {
  max-width: 70%;
  margin: auto;
  padding: 0 !important;
}
.paragraph_three {
  max-width: 60%;
}
.paragraph_three a,
.paragraph_two a {
  color: #6284ad;
  text-decoration: none;
}
.button {
  display: inline-block;
  margin: 20px 0;
  padding: 10px 20px;
  color: #ffffff !important;
  text-decoration: none;
  border-radius: 5px;
  background: linear-gradient(180deg, #0169eb 0%, #529bf6 100%);
  width: 270px;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
.footer {
  text-align: center;
  font-size: 12px;
  color: #888;
  margin-top: 20px;
  background: var(--Gray-100, #f3f4f6);
  padding: 20px;
}
.footer p {
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: center;
  color: #09223f;
}
.footer .paragraph_two {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  max-width: 80% !important;
  margin: 20px auto;
}
.social_icon {
  margin: auto;
  margin-bottom: 20px;
}
.social_icon a {
  margin: 0 10px;
}
.lifewink_link {
  color: var(--Primary-900, #0169eb);
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: center;
}
.reciver-name {
  text-align: left;
}
.paragraph_one,
.paragraph_two {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: var(--NavyB2, #1e4b61);
}
.accept-link,
.reject-link {
  display: block;
  margin: 20px auto;
  text-decoration: none;
}

.accept-link {
  color: #ffffff;
}

.reject-link {
  margin-top: 10px;
  color: #0169eb;
}
.bottom-paragraph {
  margin-block: 0;
  padding: 0;
}
a {
  color: #000;
  text-decoration: none;
}
h1 {
  font-size: 1.7rem;
  padding-block: 0;
}
.table-container {
  overflow-x: auto;
}
.info-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.info-table thead {
  background-color: #3498db;
  color: #ffffff;
}

.info-table th,
.info-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
  min-width: 300px !important;
}

.info-table th {
  font-weight: bold;
}

.info-table tr:nth-child(even) {
  background-color: #f2f2f2;
}
a {
  color: #3498db;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.effective-date {
  text-align: center;
  color: #555;
  margin-top: 30px;
  font-weight: bold;
}
