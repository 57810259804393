.title {
  margin-left: 1rem;
  margin-top: 2rem;
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.form {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
}

.input {
  margin-bottom: 0.2rem;
  padding: 0.75rem;
  font-size: 1rem;
}

.button {
  margin: 0.3rem 0.75rem;
  font-size: 1rem;
  background-color: #0169eb !important;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-weight: 600;
}

.button:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

.error {
  color: red;
}
.rememberMeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0.75rem;
}
.rememberMeLabel {
  font-family: Axiforma;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-left: 0.25rem;
}
.rememberMeCheckbox {
  border: 1px solid #d0d5dd;
  width: 16px;
  height: 16px;
  gap: 0px;
  border-radius: 4px;
  border: 1px 0px 0px 0px;
}
.forgotPasswordContainer {
  text-align: right;
  margin-bottom: 20px;
}

.forgotPasswordLink {
  font-size: 14px;
  color: #007bff;
  text-decoration: none;
}

.forgotPasswordLink:hover {
  text-decoration: underline;
}
.subHeading p,
.resendContainer p {
  font-family: Axiforma;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0 0 0 0.7rem;
  color: #475467;
}
.subHeading {
  margin-bottom: 1rem;
}
.resendContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.resendContainer span,
.backToLogin span {
  color: #0169eb;
  cursor: pointer;
  font-weight: 600;
}
.backToLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;
}
