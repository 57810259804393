.tableContainer {
  overflow-x: auto;
  border-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: #fcfcfd;
  border: 1px solid #d2d6db;
  margin: 0 20px;
}

.userTable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 16px;
  text-align: left;
  table-layout: auto;
}

.userTable th,
.userTable td {
  padding: 12px;
  text-align: left;
  word-break: break-word;
  overflow-wrap: break-word;
  max-width: 200px;
}

.userTable th {
  background-color: #0169eb;
  color: #ffffff;
  font-family: Axiforma;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.userTable tr:hover {
  background-color: #f1f1f1; /* Hover effect */
}
.userTable tr {
  font-family: Axiforma;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 10px;
  border-bottom: 1px solid #d2d6db;
}

thead tr:first-child th:first-child {
  border-top-left-radius: 5px;
}

thead tr:first-child th:last-child {
  border-top-right-radius: 5px;
}

tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;
}
table tr td:first-child {
  max-width: 100px;
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
}
.buttonWrap {
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  padding: 5px;
}
.buttonWrap > div {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}
.header h1 {
  font-family: Axiforma;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #09223f;
  margin-bottom: 8px;
}
.header p {
  font-family: Axiforma;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #475467;
  margin: 0;
}
.downloadButton > div {
  gap: 0;
}
.searchField {
  width: 330px !important;
  z-index: 1;
  height: 44px;
  margin-left: -5px;
}
.dropdownWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.filterByDate {
  min-width: 150px;
}
.datePickerContainer {
  position: absolute;
  top: 45px;
  left: 0;
  z-index: 10;
  background-color: #ffffff;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 4px;
}

.datePicker {
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
}
.filterDropdown {
  position: absolute !important;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  z-index: 100;
  top: 3.5rem;
  right: 3.5rem;
  border: 0.75px solid #d2d6db;
  display: flex;
  flex-direction: column;
  width: 300px;
}
.filterDropdown .dropdownWrapper {
  margin-bottom: 8px;
}
.filterOption {
  margin-bottom: 8px;
  padding: 10px;
}
.dropdownHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  width: 276px;
  height: 33px;
  border-bottom: 0.75px solid #f5f5f7;
}
.dropdownHeader > p {
  font-family: Axiforma;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #09223f;
}
.dropdownHeader > img {
  cursor: pointer;
  margin-bottom: auto;
}
.customDropdown {
  background-color: #f7f7f9;
  width: 260px;
  height: 40px;
  border-radius: 6px;
  padding: 5px;
  font-size: 12px;
  margin-top: 5px;
}
.filterDropdownHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20px;
  margin: auto;
}
.filterDropdownHeader > p {
  font-family: Axiforma;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #09223f;
  margin: 10px 0;
}
.filterDropdownHeader > span {
  font-family: Axiforma;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0169eb;
  cursor: pointer;
  margin-right: 10px;
}
.resetBtn {
  font-family: Axiforma;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #475467;
  height: 40px;
  width: 127px;
}
.applyBtn {
  background-color: #0169eb;
  height: 40px;
  width: 127px;
  font-family: Axiforma;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom: 1px solid #eaecf0;
  border-left: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
  margin: 0 20px;
}
.nextButton {
  background-color: #0169eb;
  color: #ffffff;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 60px;
  height: 40px;
}
.prevButton {
  background-color: #ffffff !important;
  color: #344054;
  border-radius: 5px;
  padding: 12px 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 10px;
}
.prevButton:disabled {
  color: #a4b6b6;
}
.searchButton {
  padding-right: 20px;
  padding-left: 20px;
  background-color: #0169eb;
  color: #ffffff;
}
.filterButton {
  padding-right: 20px;
  padding-left: 20px;
  font-weight: 500;
  color: #09223f;
}
.filterButton > div {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}
.dateRange {
  position: absolute;
  top: 3.2rem;
  right: 11rem;
  z-index: 100;
}
.dateRange > div {
  max-width: 400px;
  font-size: 10px;
}
