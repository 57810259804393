.dropdownContainer {
  position: relative;
  width: 100%;
  max-width: 300px;
}

.dropdown {
  width: 100%;
  padding: 10px 3rem 10px 1rem;
  font-size: 14px;
  border-radius: 10px;
  background-color: white;
  cursor: pointer;
  border: 1.5px solid #d2d6db;
  transition: border-color 0.3s ease;
  text-align: start;
  background: url('../../assets/feedback/dropdown.svg') no-repeat right 1rem
    center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #09223f;
  font-weight: 500;
}
.dropdownList::-webkit-scrollbar {
  width: 5px;
}

.dropdownList::-webkit-scrollbar-thumb {
  background-color: #a7a7a7;
  border-radius: 10px;
}

.dropdownList::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.dropdown:disabled {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.dropdown:focus {
  border-color: #0169eb;
  outline: none;
}

.dropdown.selected {
  border-color: #0169eb;
}

.dropdownList {
  position: absolute;
  width: 100%;
  height: 250px !important;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #d2d6db;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 100;
  margin-top: 5px;
  padding: 0;
  list-style-type: none;
  margin: 0;
  border-radius: 10px;
  top: 44px;
  color: #09223f;
  font-weight: 500;
}

.dropdownItem {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
}

.dropdownItem:hover {
  background-color: #f1f1f1;
}
