/* Button.module.css */
.button {
  height: 44px;
  padding: 10px 14px;
  border-radius: 8px;
  border: 1.5px solid #d2d6db;
  background-color: #f9fafb;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button:disabled {
  background-color: #cccc;
  cursor: not-allowed;
}

.content {
  display: flex;
  align-items: center;
}
.icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.icon.left {
  margin-right: 8px;
}

.icon.right {
  margin-left: 8px;
}

.text {
  display: inline-flex;
  align-items: center;
}
