.dropdown {
  text-align: start;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 1rem;
  padding-right: 3rem;
  font-size: 14px;
  border-radius: 10px;
  background-color: white;
  cursor: pointer;
  border: 1.5px solid #d2d6db;
  transition: border-color 0.3s ease;
  appearance: none; /* Removes default arrow */
  -webkit-appearance: none; /* Safari compatibility */
  -moz-appearance: none; /* Firefox compatibility */
  background: url('../../assets/feedback/dropdown.svg') no-repeat right 1rem
    center;
}

.dropdown:disabled {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.dropdown:focus {
  border-color: #0169eb;
  outline: none;
}

.dropdown.selected {
  border-color: #0169eb;
}
