.searchField {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 1.5px solid #d2d6db;
  border-radius: 10px;
  background-color: #ffffff;
  transition: border-color 0.3s ease;
  width: 100%; /* Adjust as needed */
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  object-fit: contain;
  cursor: pointer;
}

.icon.right {
  margin-left: 8px;
  margin-right: 0;
}

.input {
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 14px;
  background-color: transparent;
  width: 100%;
}

.input:disabled {
  background-color: #f0f0f0;
  cursor: not-allowed;
}
