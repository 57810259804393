.container {
  display: flex;
  height: 100vh;
  background-color: #0169eb;
}

.sidebar {
  width: 180px;
  color: #ffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100% - 10px);
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  padding: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  height: 40px;
  color: #ffff;
}
.sidebar ul li img {
  margin-bottom: 5px;
}
.sidebar ul li:hover,
.active {
  background-color: #ffffff;
  color: #0169eb !important;
  border-radius: 6px;
}
.sidebar ul li:hover > img,
.active > img {
  filter: brightness(0) saturate(100%) invert(35%) sepia(100%) saturate(1000%)
    hue-rotate(190deg) brightness(90%) contrast(95%);
}

.content {
  margin-left: 180px;
  width: calc(100% - 180px);
  background-color: #ecf0f1;
  height: 100vh;
  overflow-y: auto;
}

h1 {
  margin-bottom: 20px;
}
.logo {
  height: 35px;
  width: 140px;
  margin-bottom: 20px;
}
.navIcon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}
.content {
  background-color: #0169eb;
  border-color: #eaecf0;
  padding-top: 12px;
}
.content > div {
  background-color: #fcfcfd;
  border-top-left-radius: 32px;
  min-height: calc(100vh - 12px) !important;
}
.profile {
  cursor: pointer;
  border-top: #eaecf0 1px solid;
  padding-top: 12px;
  text-align: right;
  display: flex;
  justify-content: center;
}
.profile span {
  margin-right: 10px;
}
.buttonWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.yesButton {
  background: #e5e7eb;
  width: 150px;
  height: 40px;
  color: #475467;
  font-family: Axiforma;
  font-size: 15px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.cancelButton {
  background: linear-gradient(180deg, #0169eb 0%, #529bf6 100%);
  width: 150px;
  height: 40px;
  color: #ffffff;
  font-family: Axiforma;
  font-size: 15px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.modalContent h2 {
  font-family: Axiforma;
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #09223f;
}
.modalContent p {
  font-family: Axiforma;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #475467;
}
.modalContent img {
  margin: 0 auto;
}
