.container {
  max-width: 1000px;
  margin: auto;
  background-color: #ffffff;
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  overflow: hidden;
  padding: 0 !important;
}

.header-about-us {
  text-align: center;
  padding-bottom: 20px;
  /* background-image: url('https://lifewink-common.s3.us-east-2.amazonaws.com/emailAssets/background.png'); */
  background-size: cover;
  background-position: center;
  height: 130px;
  background-color: #0169EB;
}

.lifewink_logo {
  margin-top: 30px;
}

.content {
  text-align: left;
  padding: 1rem;
}

.content h1 {
  font-size: 30px;
  font-weight: 700;
  line-height: 36.31px;
}

.content p {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 5px 0;
}

.paragraph_two,
.paragraph_three {
  max-width: 70%;
  margin: auto;
  padding: 0 !important;
}

.paragraph_three {
  max-width: 60%;
}

.paragraph_three a,
.paragraph_two a {
  color: #6284ad;
  text-decoration: none;
}