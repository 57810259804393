.container {
  max-width: 800px;
  margin: 5rem auto;
  text-align: center;
  font-family: Arial, sans-serif;
  border: #e5e7eb solid 1px;
  padding-bottom: 20px;
  box-shadow: 0 2px 5px rgba(48, 47, 47, 0.1);
}

.title {
  font-size: 30px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #09223f;
}

.button {
  background: linear-gradient(180deg, #0169eb 0%, #529bf6 100%);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin: 1rem auto !important;
  width: 270px;
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.subscriptionWrap {
  margin: 3rem 3rem 0 3rem;
}
.subscriptionWrap h1 {
  font-size: 30px;
  font-weight: 600;
  line-height: 36.31px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 1rem;
  color: #09223f;
}
.subscribe {
  color: #0169eb;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;
}
.button.unsubscribe {
  background-color: #dc3545;
}

.message {
  font-size: 14px;
  color: #666;
  margin-top: 20px;
}

.success {
  color: green;
}

.error {
  color: red;
}
.header {
  text-align: center;
  padding-bottom: 20px;
  background-image: url('https://lifewink-common.s3.us-east-2.amazonaws.com/emailAssets/background.png');
  background-size: cover;
  background-position: center;
  height: 130px;
}
.logo {
  margin-top: 30px;
}
.container > div {
  left: 0 !important;
}
