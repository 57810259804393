.textAreaContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  position: relative !important;
}

.label {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #344054;
  margin-bottom: 8px;
}

.textArea {
  padding: 0.75rem;
  font-size: 1rem;
  transition: border-color 0.3s;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px #1018280d;
  background: #f5f5f5;
  color: #667085;
  resize: vertical;
  height: auto;
  min-height: 100px;
}

.textArea:focus {
  border-color: #007bff;
  outline: none;
}

.errorInput {
  border-color: red;
}

.error {
  color: red;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  text-align: start;
}
