/* Overlay */
.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 1111; /* Ensure it's above other elements */
}

/* Modal */
.modal {
  background-color: #ffffff !important; /* Force white background */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.04);
  position: relative;
  width: 350px;
}

/* Close button */
.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  background-color: transparent;
}
body.modal-open {
  pointer-events: none;
}

body.modal-open .overlay {
  pointer-events: auto;
}
