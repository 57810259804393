.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  position: relative !important;
}

.label {
  font-family: 'Axiforma', sans-serif; /* Use Axiforma font */
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #344054;
  margin-bottom: 8px;
}

.input {
  padding: 0.75rem;
  font-size: 1rem;
  font-family: 'Axiforma', sans-serif; /* Use Axiforma font */
  transition: border-color 0.3s;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px #1018280d;
  background: #f5f5f5;
  color: #667085;
  height: 44px;
  margin-bottom: 15px;
}
.passwordIcon {
  position: absolute;
  right: 23px;
  top: 49px;
  cursor: pointer;
  width: 20px;
  z-index: 100;
}
.input:focus {
  border-color: #007bff;
  outline: none;
}
.input:focus ~ .passwordIcon {
  filter: invert(25%) sepia(84%) saturate(748%) hue-rotate(209deg)
    brightness(92%) contrast(92%);
}

.errorInput {
  border-color: red;
}

.error {
  color: red;
  margin-top: 0.25rem;
  font-size: 0.875rem;
}
