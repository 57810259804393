/* Footer.css */
.footer {
  text-align: center;
  /* font-size: 12px; */
  color: #888;
  margin-top: 20px;
  background: #f3f4f6;
  padding: 20px;
}

.footer p {
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: center;
  color: #09223f;
}

.footer .paragraph_two {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  max-width: 80% !important;
  margin: 20px auto;
}

.social_icon {
  margin: auto;
  margin-bottom: 20px;
}

.social_icon a {
  margin: 0 10px;
}

.lifewink_link {
  color: #0169eb;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: center;
}
/* Footer.css */
.footer-links {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer-link {
  font-size: 14px;
  font-weight: 500;
  color: #0169eb;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}
