.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
}

.supportBox {
  width: 800px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #0000001a;
  background-color: #fff;
  text-align: center;
}
.title img {
  margin-right: 10px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.title {
  display: flex;
}
.title h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.feedbackText {
  margin-top: 20px;
  text-align: left;
}
.feedbackText h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #09223f;
}
.feedbackText p {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #475467;
}
.experience,
.improvement p {
  font-size: 16px;
  font-weight: 600;
  line-height: 26.1px;
  text-align: left;
  color: #1e4b61;
}
.improvement {
  margin-top: 20px;
}
.improvementGrid {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  gap: 10px;
  margin-bottom: 2rem;
}
.improvementItem {
  display: flex;
  justify-content: start;
  align-items: start;
  text-align: start;
  transition: all 0.3s;
}
.selectedItem > .improvementAreas {
  background-color: #007bff;
  color: white;
  border-color: #0056b3;
}

.improvementAreas {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #475467;
  background-color: #e6f0fd;
  padding: 8px 10px 8px 10px;
  border-radius: 8px;
  cursor: pointer;
}
.description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}
.icons {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
}
.icon:hover {
  transform: scale(1.1);
}

.greenIcon {
  filter: invert(25%) sepia(90%) saturate(1495%) hue-rotate(93deg)
    brightness(100%) contrast(109%);
}

.yellowIcon {
  filter: invert(68%) sepia(92%) saturate(2076%) hue-rotate(21deg)
    brightness(101%) contrast(101%);
}

.redIcon {
  filter: invert(13%) sepia(93%) saturate(5844%) hue-rotate(356deg)
    brightness(92%) contrast(120%);
}
.error {
  color: red;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  text-align: start;
  font-weight: 400;
  font-size: 10px;
}
.submitBtn {
  width: 327px;
  height: 48px;
  padding: 12px 16px 12px 16px;
  border-radius: 12px;
  background: linear-gradient(180deg, #0169eb 0%, #529bf6 100%);
  color: #ffffff;
  border: none;
  margin-left: auto;
  justify-content: center;
  display: flex;
  cursor: pointer;
}
.disabledButton {
  background: rgb(201, 200, 200);
  cursor: none;
  color: #475467;
}
