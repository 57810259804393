.container {
  display: flex;
  height: 100vh;
}

.leftSide {
  flex: 1;
  background-image: url('/src/assets/login/login_background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.characters {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15%;
  max-width: 70%;
}
.characters h3 {
  font-family: Axiforma;
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}
.characters p {
  font-family: Axiforma;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}
.rightSide {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}
.rightWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 360px;
}
.title {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 12px;
  margin-right: 0px;
}
h2 {
  margin: 0.8rem;
}
.logo {
  margin-left: 0.4rem;
}
